import * as i0 from '@angular/core';
import { inject, Directive, Input, HostListener, Component, ChangeDetectionStrategy, InjectionToken, Inject, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef, DIALOG_DATA, Dialog } from '@angular/cdk/dialog';
import { LfIconComponent } from '@leafio/ui/icons';
import { LfButton } from '@leafio/ui/buttons';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';
import { LfAccentClassDirective } from '@leafio/ui/utils';
const _c0 = [[["lf-dialog-header-icon"]], "*"];
const _c1 = ["lf-dialog-header-icon", "*"];
const _c2 = ["*"];
function LfConfirmationDialog_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function LfConfirmationDialog_Conditional_6_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.extraAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("mode", ctx_r0.extraBtn.mode)("accent", ctx_r0.extraBtn.accent)("size", ctx_r0.extraBtn.size);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 4, ctx_r0.extraBtn.text));
  }
}
function LfConfirmationDialog_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵlistener("click", function LfConfirmationDialog_Conditional_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.secondaryAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("mode", ctx_r1.secondaryBtn.mode)("accent", ctx_r1.secondaryBtn.accent)("size", ctx_r1.secondaryBtn.size);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 4, ctx_r1.secondaryBtn.text));
  }
}
function LfConfirmationDialog_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function LfConfirmationDialog_Conditional_8_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.primaryAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("mode", ctx_r2.primaryBtn.mode)("accent", ctx_r2.primaryBtn.accent)("size", ctx_r2.primaryBtn.size);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 4, ctx_r2.primaryBtn.text));
  }
}
class LfDialogClose {
  constructor() {
    this.dialogRef = inject(DialogRef);
  }
  onClick() {
    this.dialogRef.close(this.dialogResult);
  }
  static {
    this.ɵfac = function LfDialogClose_Factory(t) {
      return new (t || LfDialogClose)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfDialogClose,
      selectors: [["", "lfDialogClose", ""]],
      hostBindings: function LfDialogClose_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function LfDialogClose_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        dialogResult: [i0.ɵɵInputFlags.None, "lfDialogClose", "dialogResult"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogClose, [{
    type: Directive,
    args: [{
      selector: '[lfDialogClose]',
      standalone: true
    }]
  }], null, {
    dialogResult: [{
      type: Input,
      args: ['lfDialogClose']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class LfDialogHeader {
  static {
    this.ɵfac = function LfDialogHeader_Factory(t) {
      return new (t || LfDialogHeader)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfDialogHeader,
      selectors: [["lf-dialog-header"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 5,
      vars: 0,
      consts: [[1, "title"], ["type", "button", "lfDialogClose", "", "tabindex", "-1", 1, "close-btn"], ["name", "close_bold"]],
      template: function LfDialogHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 0);
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "button", 1);
          i0.ɵɵelement(4, "lf-icon", 2);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule, LfDialogClose, LfIconComponent],
      styles: ["[_nghost-%COMP%]{display:flex;align-items:center;flex:0 0 auto;gap:var(--lf-dialog-header-gap);padding:var(--lf-dialog-header-padding-vertical) var(--lf-dialog-header-padding-horizontal);background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300)}[_nghost-%COMP%]:first-child{border-radius:var(--lf-dialog-border-radius) var(--lf-dialog-border-radius) 0 0}.lf-no-divider[_nghost-%COMP%]{border-bottom-width:0}.title[_ngcontent-%COMP%]{font:var(--lf-dialog-header-font);margin-right:auto}.close-btn[_ngcontent-%COMP%]{display:flex;flex:0 0 auto;width:var(--lf-dialog-header-close-btn-size);height:var(--lf-dialog-header-close-btn-size);align-items:center;justify-content:center;font-size:var(--lf-dialog-header-close-btn-icon-size);margin:0;padding:0;border:none;outline:none;border-radius:50%;cursor:pointer;transition-duration:var(--lf-transition-duration);transition-property:color,background-color;background-color:var(--lf-main-300);color:var(--lf-main-900)}.close-btn[_ngcontent-%COMP%]:hover, .close-btn[_ngcontent-%COMP%]:focus-visible{background-color:var(--lf-main-500)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogHeader, [{
    type: Component,
    args: [{
      selector: 'lf-dialog-header',
      standalone: true,
      imports: [CommonModule, LfDialogClose, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content select=\"lf-dialog-header-icon\"></ng-content>\n<div class=\"title\">\n  <ng-content></ng-content>\n</div>\n<button type=\"button\" lfDialogClose class=\"close-btn\" tabindex=\"-1\"><lf-icon name=\"close_bold\"></lf-icon></button>\n",
      styles: [":host{display:flex;align-items:center;flex:0 0 auto;gap:var(--lf-dialog-header-gap);padding:var(--lf-dialog-header-padding-vertical) var(--lf-dialog-header-padding-horizontal);background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300)}:host:first-child{border-radius:var(--lf-dialog-border-radius) var(--lf-dialog-border-radius) 0 0}:host.lf-no-divider{border-bottom-width:0}.title{font:var(--lf-dialog-header-font);margin-right:auto}.close-btn{display:flex;flex:0 0 auto;width:var(--lf-dialog-header-close-btn-size);height:var(--lf-dialog-header-close-btn-size);align-items:center;justify-content:center;font-size:var(--lf-dialog-header-close-btn-icon-size);margin:0;padding:0;border:none;outline:none;border-radius:50%;cursor:pointer;transition-duration:var(--lf-transition-duration);transition-property:color,background-color;background-color:var(--lf-main-300);color:var(--lf-main-900)}.close-btn:hover,.close-btn:focus-visible{background-color:var(--lf-main-500)}\n"]
    }]
  }], null, null);
})();
class LfDialogHeaderIcon {
  constructor() {
    this.accent = 'neutral';
  }
  static {
    this.ɵfac = function LfDialogHeaderIcon_Factory(t) {
      return new (t || LfDialogHeaderIcon)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfDialogHeaderIcon,
      selectors: [["lf-dialog-header-icon"]],
      hostVars: 2,
      hostBindings: function LfDialogHeaderIcon_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap("lf-" + ctx.accent);
        }
      },
      inputs: {
        accent: "accent",
        name: "name"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[1, "icon", 3, "name"]],
      template: function LfDialogHeaderIcon_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "lf-icon", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx.name);
        }
      },
      dependencies: [CommonModule, LfIconComponent],
      styles: ["[_nghost-%COMP%]{display:flex;align-items:center;justify-content:center;flex:0 0 auto;width:var(--lf-dialog-header-icon-wrapper-size);height:var(--lf-dialog-header-icon-wrapper-size);border-radius:50%;background-color:var(--lf-current-accent-100)}.icon[_ngcontent-%COMP%]{font-size:var(--lf-dialog-header-icon-size);color:var(--lf-current-accent-500)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogHeaderIcon, [{
    type: Component,
    args: [{
      selector: 'lf-dialog-header-icon',
      standalone: true,
      imports: [CommonModule, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class]': `'lf-' + accent`
      },
      template: "<lf-icon class=\"icon\" [name]=\"name\"></lf-icon>\n",
      styles: [":host{display:flex;align-items:center;justify-content:center;flex:0 0 auto;width:var(--lf-dialog-header-icon-wrapper-size);height:var(--lf-dialog-header-icon-wrapper-size);border-radius:50%;background-color:var(--lf-current-accent-100)}.icon{font-size:var(--lf-dialog-header-icon-size);color:var(--lf-current-accent-500)}\n"]
    }]
  }], null, {
    accent: [{
      type: Input
    }],
    name: [{
      type: Input
    }]
  });
})();
class LfDialogContent {
  static {
    this.ɵfac = function LfDialogContent_Factory(t) {
      return new (t || LfDialogContent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfDialogContent,
      selectors: [["lf-dialog-content"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 1,
      vars: 0,
      template: function LfDialogContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{display:block;flex:1 1 auto;padding:var(--lf-dialog-content-padding-vertical) var(--lf-dialog-content-padding-horizontal);background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300);border-top-width:0;border-bottom-width:0}[_nghost-%COMP%]:first-child{border-radius:var(--lf-dialog-border-radius) var(--lf-dialog-border-radius) 0 0;border-top-width:var(--lf-dialog-border-width)}[_nghost-%COMP%]:last-child{border-radius:0 0 var(--lf-dialog-border-radius) var(--lf-dialog-border-radius);border-bottom-width:var(--lf-dialog-border-width)}.lf-align-with-header-icon[_nghost-%COMP%]{padding-left:calc(var(--lf-dialog-header-padding-horizontal) + var(--lf-dialog-header-gap) + var(--lf-dialog-header-icon-wrapper-size))}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogContent, [{
    type: Component,
    args: [{
      selector: 'lf-dialog-content',
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:block;flex:1 1 auto;padding:var(--lf-dialog-content-padding-vertical) var(--lf-dialog-content-padding-horizontal);background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300);border-top-width:0;border-bottom-width:0}:host:first-child{border-radius:var(--lf-dialog-border-radius) var(--lf-dialog-border-radius) 0 0;border-top-width:var(--lf-dialog-border-width)}:host:last-child{border-radius:0 0 var(--lf-dialog-border-radius) var(--lf-dialog-border-radius);border-bottom-width:var(--lf-dialog-border-width)}:host.lf-align-with-header-icon{padding-left:calc(var(--lf-dialog-header-padding-horizontal) + var(--lf-dialog-header-gap) + var(--lf-dialog-header-icon-wrapper-size))}\n"]
    }]
  }], null, null);
})();
class LfDialogFooter {
  static {
    this.ɵfac = function LfDialogFooter_Factory(t) {
      return new (t || LfDialogFooter)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfDialogFooter,
      selectors: [["lf-dialog-footer"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 1,
      vars: 0,
      template: function LfDialogFooter_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{display:flex;flex:0 0 auto;align-items:center;padding:var(--lf-dialog-footer-padding-vertical) var(--lf-dialog-footer-padding-horizontal);gap:var(--lf-dialog-footer-gap);justify-content:flex-end;background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300)}[_nghost-%COMP%]:last-child{border-radius:0 0 var(--lf-dialog-border-radius) var(--lf-dialog-border-radius)}.lf-no-divider[_nghost-%COMP%]{border-top-width:0}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogFooter, [{
    type: Component,
    args: [{
      selector: 'lf-dialog-footer',
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:flex;flex:0 0 auto;align-items:center;padding:var(--lf-dialog-footer-padding-vertical) var(--lf-dialog-footer-padding-horizontal);gap:var(--lf-dialog-footer-gap);justify-content:flex-end;background-color:var(--lf-bg-100);border:var(--lf-dialog-border-width) solid var(--lf-main-300)}:host:last-child{border-radius:0 0 var(--lf-dialog-border-radius) var(--lf-dialog-border-radius)}:host.lf-no-divider{border-top-width:0}\n"]
    }]
  }], null, null);
})();
const LF_CONFIRMATION_DIALOG_DEFAULT_TITLE = new InjectionToken('LF_CONFIRMATION_DIALOG_DEFAULT_TITLE', {
  factory: () => 'Confirmation'
});
const LF_CONFIRMATION_DIALOG_DEFAULT_CONTENT = new InjectionToken('LF_CONFIRMATION_DIALOG_DEFAULT_CONTENT', {
  factory: () => 'Are you sure?'
});
const LF_CONFIRMATION_DIALOG_DEFAULT_PRIMARY_BTN_CONFIG = new InjectionToken('LF_CONFIRMATION_DIALOG_DEFAULT_PRIMARY_BTN_CONFIG', {
  factory: () => ({})
});
const LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG = new InjectionToken('LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG', {
  factory: () => ({})
});
const LF_CONFIRMATION_DIALOG_DEFAULT_EXTRA_BTN_CONFIG = new InjectionToken('LF_CONFIRMATION_DIALOG_DEFAULT_EXTRA_BTN_CONFIG', {
  factory: () => ({})
});
function noop() {}
const DEFAULT_PRIMARY_BTN_CONFIG = {
  show: true,
  accent: 'brand',
  mode: 'primary',
  text: 'Yes',
  size: 'xl'
};
const DEFAULT_SECONDARY_BTN_CONFIG = {
  show: true,
  accent: 'brand',
  mode: 'secondary',
  text: 'No',
  size: 'xl'
};
const DEFAULT_EXTRA_BTN_CONFIG = {
  show: true,
  accent: 'brand',
  mode: 'ghost',
  text: '',
  size: 'xl'
};
class LfConfirmationDialog {
  constructor(data) {
    this.data = data;
    this.DEFAULT_TITLE = inject(LF_CONFIRMATION_DIALOG_DEFAULT_TITLE);
    this.DEFAULT_CONTENT = inject(LF_CONFIRMATION_DIALOG_DEFAULT_CONTENT);
    this.DEFAULT_CONTENT_INTERPOLATION = {};
    this.DEFAULT_PRIMARY_BTN_CONFIG = inject(LF_CONFIRMATION_DIALOG_DEFAULT_PRIMARY_BTN_CONFIG);
    this.DEFAULT_SECONDARY_BTN_CONFIG = inject(LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG);
    this.DEFAULT_EXTRA_BTN_CONFIG = inject(LF_CONFIRMATION_DIALOG_DEFAULT_EXTRA_BTN_CONFIG);
    this.title = data.title || this.DEFAULT_TITLE;
    this.content = data.content || this.DEFAULT_CONTENT;
    this.contentInterpolation = data.contentInterpolation || this.DEFAULT_CONTENT_INTERPOLATION;
    this.primaryBtn = {
      ...DEFAULT_PRIMARY_BTN_CONFIG,
      ...this.DEFAULT_PRIMARY_BTN_CONFIG,
      ...data.primaryBtn
    };
    this.secondaryBtn = {
      ...DEFAULT_SECONDARY_BTN_CONFIG,
      ...this.DEFAULT_SECONDARY_BTN_CONFIG,
      ...data.secondaryBtn
    };
    this.extraBtn = {
      ...DEFAULT_EXTRA_BTN_CONFIG,
      ...this.DEFAULT_EXTRA_BTN_CONFIG,
      ...data.extraBtn
    };
    this.primaryAction = data.primaryAction || noop;
    this.secondaryAction = data.secondaryAction || noop;
    this.extraAction = data.extraAction || noop;
  }
  static {
    this.ɵfac = function LfConfirmationDialog_Factory(t) {
      return new (t || LfConfirmationDialog)(i0.ɵɵdirectiveInject(DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfConfirmationDialog,
      selectors: [["lf-confirmation-dialog"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 11,
      consts: [[1, "lf-text-l", 3, "innerHTML"], ["type", "button", "lfBtn", "", "lfDialogClose", "extra", 3, "mode", "accent", "size"], ["type", "button", "lfBtn", "", "lfDialogClose", "secondary", 3, "mode", "accent", "size"], ["type", "button", "lfBtn", "", "lfDialogClose", "primary", 3, "mode", "accent", "size"], ["type", "button", "lfBtn", "", "lfDialogClose", "extra", 3, "mode", "accent", "size", "click"], ["type", "button", "lfBtn", "", "lfDialogClose", "secondary", 3, "mode", "accent", "size", "click"], ["type", "button", "lfBtn", "", "lfDialogClose", "primary", 3, "mode", "accent", "size", "click"]],
      template: function LfConfirmationDialog_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "lf-dialog-header");
          i0.ɵɵtext(1);
          i0.ɵɵpipe(2, "translate");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "lf-dialog-content", 0);
          i0.ɵɵpipe(4, "translate");
          i0.ɵɵelementStart(5, "lf-dialog-footer");
          i0.ɵɵtemplate(6, LfConfirmationDialog_Conditional_6_Template, 3, 6, "button", 1)(7, LfConfirmationDialog_Conditional_7_Template, 3, 6, "button", 2)(8, LfConfirmationDialog_Conditional_8_Template, 3, 6, "button", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 5, ctx.title, ctx.contentInterpolation));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind2(4, 8, ctx.content, ctx.contentInterpolation), i0.ɵɵsanitizeHtml);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(6, ctx.extraBtn.show && ctx.extraBtn.text ? 6 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(7, ctx.secondaryBtn.show ? 7 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(8, ctx.primaryBtn.show ? 8 : -1);
        }
      },
      dependencies: [LfDialogHeader, LfDialogContent, LfDialogFooter, LfButton, LfDialogClose, TranslateModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfConfirmationDialog, [{
    type: Component,
    args: [{
      selector: 'lf-confirmation-dialog',
      standalone: true,
      imports: [LfDialogHeader, LfDialogContent, LfDialogFooter, LfButton, LfDialogClose, TranslateModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<lf-dialog-header>{{ title | translate:contentInterpolation }}</lf-dialog-header>\n<lf-dialog-content class=\"lf-text-l\" [innerHTML]=\"content | translate:contentInterpolation\"></lf-dialog-content>\n<lf-dialog-footer>\n  @if (extraBtn.show && extraBtn.text) {\n    <button type=\"button\"\n            lfBtn\n            lfDialogClose=\"extra\"\n            [mode]=\"extraBtn.mode\"\n            [accent]=\"extraBtn.accent\"\n            [size]=\"extraBtn.size\"\n            (click)=\"extraAction()\"\n    >{{ extraBtn.text | translate }}</button>\n  }\n  @if (secondaryBtn.show) {\n    <button type=\"button\"\n            lfBtn\n            lfDialogClose=\"secondary\"\n            [mode]=\"secondaryBtn.mode\"\n            [accent]=\"secondaryBtn.accent\"\n            [size]=\"secondaryBtn.size\"\n            (click)=\"secondaryAction()\"\n    >{{ secondaryBtn.text | translate }}</button>\n  }\n  @if (primaryBtn.show) {\n    <button type=\"button\"\n            lfBtn\n            lfDialogClose=\"primary\"\n            [mode]=\"primaryBtn.mode\"\n            [accent]=\"primaryBtn.accent\"\n            [size]=\"primaryBtn.size\"\n            (click)=\"primaryAction()\"\n    >{{ primaryBtn.text | translate }}</button>\n  }\n\n</lf-dialog-footer>\n",
      styles: [":host{display:block}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DIALOG_DATA]
    }]
  }], null);
})();
var LfDialogSize;
(function (LfDialogSize) {
  LfDialogSize["xs"] = "400px";
  LfDialogSize["s"] = "500px";
  LfDialogSize["m"] = "600px";
  LfDialogSize["l"] = "800px";
})(LfDialogSize || (LfDialogSize = {}));
const LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG = new InjectionToken('LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG', {
  factory: () => ({})
});
class LfConfirmActionDialog {
  constructor(data) {
    this.data = data;
    this.LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG = inject(LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG);
    this.defaultConfig = {
      title: 'Confirm action',
      icon: 'warning_circle',
      content: 'Are you sure?',
      accent: 'attention',
      btnSize: 'xl',
      cancelBtnText: 'buttons.cancel',
      confirmBtnText: 'Yes',
      contentInterpolation: {},
      ...this.LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG
    };
    this.config = {
      ...this.defaultConfig,
      ...data
    };
  }
  static {
    this.ɵfac = function LfConfirmActionDialog_Factory(t) {
      return new (t || LfConfirmActionDialog)(i0.ɵɵdirectiveInject(DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfConfirmActionDialog,
      selectors: [["lf-confirm-action-dialog"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 13,
      vars: 21,
      consts: [[1, "lf-no-divider"], [3, "name", "accent"], [1, "lf-align-with-header-icon", "content", "lf-text-l", 3, "innerHTML"], ["type", "button", "lfBtn", "", "mode", "ghost", "accent", "neutral", 3, "lfDialogClose", "size"], ["type", "button", "lfBtn", "", "mode", "primary", 3, "lfDialogClose", "accent", "size"]],
      template: function LfConfirmActionDialog_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "lf-dialog-header", 0);
          i0.ɵɵelement(1, "lf-dialog-header-icon", 1);
          i0.ɵɵtext(2);
          i0.ɵɵpipe(3, "translate");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(4, "lf-dialog-content", 2);
          i0.ɵɵpipe(5, "translate");
          i0.ɵɵelementStart(6, "lf-dialog-footer", 0)(7, "button", 3);
          i0.ɵɵtext(8);
          i0.ɵɵpipe(9, "translate");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(10, "button", 4);
          i0.ɵɵtext(11);
          i0.ɵɵpipe(12, "translate");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("name", ctx.config.icon)("accent", ctx.config.accent);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(3, 11, ctx.config.title, ctx.config.contentInterpolation), "\n");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind2(5, 14, ctx.config.content, ctx.config.contentInterpolation), i0.ɵɵsanitizeHtml);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("lfDialogClose", false)("size", ctx.config.btnSize);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(9, 17, ctx.config.cancelBtnText));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("lfDialogClose", true)("accent", ctx.config.accent)("size", ctx.config.btnSize);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(12, 19, ctx.config.confirmBtnText));
        }
      },
      dependencies: [CommonModule, LfDialogHeader, LfDialogContent, LfDialogFooter, LfButton, LfDialogClose, TranslateModule, i1.TranslatePipe, LfDialogHeaderIcon],
      styles: ["[_nghost-%COMP%]{display:block}.content[_ngcontent-%COMP%]{padding-top:12px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfConfirmActionDialog, [{
    type: Component,
    args: [{
      selector: 'lf-confirm-action-dialog',
      standalone: true,
      imports: [CommonModule, LfIconComponent, LfDialogHeader, LfDialogContent, LfDialogFooter, LfButton, LfDialogClose, TranslateModule, LfAccentClassDirective, LfDialogHeaderIcon],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<lf-dialog-header class=\"lf-no-divider\">\n  <lf-dialog-header-icon [name]=\"config.icon\" [accent]=\"config.accent\"></lf-dialog-header-icon>\n  {{ config.title | translate:config.contentInterpolation }}\n</lf-dialog-header>\n\n<lf-dialog-content class=\"lf-align-with-header-icon content lf-text-l\" [innerHTML]=\"config.content | translate:config.contentInterpolation\">\n</lf-dialog-content>\n\n<lf-dialog-footer class=\"lf-no-divider\">\n  <button type=\"button\"\n          lfBtn\n          [lfDialogClose]=\"false\"\n          mode=\"ghost\"\n          accent=\"neutral\"\n          [size]=\"config.btnSize\"\n  >{{ config.cancelBtnText | translate }}</button>\n  <button type=\"button\"\n          lfBtn\n          [lfDialogClose]=\"true\"\n          mode=\"primary\"\n          [accent]=\"config.accent\"\n          [size]=\"config.btnSize\"\n  >{{ config.confirmBtnText | translate }}</button>\n</lf-dialog-footer>\n",
      styles: [":host{display:block}.content{padding-top:12px}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DIALOG_DATA]
    }]
  }], null);
})();
class LfDialogService {
  constructor() {
    this.dialog = inject(Dialog);
  }
  showConfirmation(config = {}, width = LfDialogSize.m) {
    return this.dialog.open(LfConfirmationDialog, {
      panelClass: 'lf-overlay-content_animated',
      data: config,
      width
    });
  }
  confirmAction(config = {}, width = LfDialogSize.s) {
    return this.dialog.open(LfConfirmActionDialog, {
      data: config,
      width,
      panelClass: 'lf-overlay-content_animated'
    }).closed.pipe(map(result => result || false));
  }
  open(componentOrTemplateRef, config = {}) {
    return this.dialog.open(componentOrTemplateRef, {
      panelClass: 'lf-overlay-content_animated',
      ...config
    });
  }
  static {
    this.ɵfac = function LfDialogService_Factory(t) {
      return new (t || LfDialogService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfDialogService,
      factory: LfDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LF_CONFIRMATION_DIALOG_DEFAULT_CONTENT, LF_CONFIRMATION_DIALOG_DEFAULT_EXTRA_BTN_CONFIG, LF_CONFIRMATION_DIALOG_DEFAULT_PRIMARY_BTN_CONFIG, LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG, LF_CONFIRMATION_DIALOG_DEFAULT_TITLE, LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG, LfDialogClose, LfDialogContent, LfDialogFooter, LfDialogHeader, LfDialogHeaderIcon, LfDialogService, LfDialogSize };
